import { Button, Box as Container } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GraveData } from '../../../services/api';
import getClasses from '../classes';

interface ExternalButtonProps {
	grave: GraveData;
}

const ExternalButton = ({ grave }: ExternalButtonProps) => {
	const { i18n } = useTranslation();
	const classes = getClasses();

	const btn = getButtonData(grave, i18n.language);

	function getButtonData(grave: GraveData, lang: string): [string, string] | null {
		if (lang === 'fi' && grave.extBtnTxtFi && grave.extUrlFi) {
			return [grave.extUrlFi, grave.extBtnTxtFi];
		}

		if (lang === 'sv' && grave.extBtnTxtSv && grave.extUrlSv) {
			return [grave.extUrlSv, grave.extBtnTxtSv];
		}

		if (lang === 'en' && grave.extBtnTxtEn && grave.extUrlEn) {
			return [grave.extUrlEn, grave.extBtnTxtEn];
		}
		return null;
	}

	function handleClick() {
		if (!btn) return;
		window.open(btn[0], '_blank');
	}

	if (btn === null) return <></>;

	return (
		<Container className={classes.containerExternalButton}>
			<Button variant="contained" color="primary" onClick={handleClick}>
				{btn[1]}
			</Button>
		</Container>
	);
};

export default ExternalButton;
