import {
	Button,
	Box as Container,
	Grid,
	Hidden,
	SwipeableDrawer,
	useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Add, ArrowBack, Fullscreen, FullscreenExit, Remove } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import graveListAtom from '../../atoms/graveList';
import { CITINOMADI_GRAVE_ID_URL_PARAM, HAUTAKARTTA_GRAVE_ID_URL_PARAM } from '../../constants';
import { Congregation } from '../../services/api';
import { useStateGrave } from '../common/useStateGrave';
import getClasses from './classes';
import InfoSection from './components/InfoSection';

interface MapViewViewParams {
	id: string;
	name: string;
}

export function MapViewView() {
	const [fullWidth, setFullWidth] = useState(false);
	const [expandDrawer, setExpandDrawer] = useState(false);

	const history = useHistory();
	const theme = useTheme();
	const desktopSize = useMediaQuery(theme.breakpoints.up('md'));
	const grave = useStateGrave();
	const { t } = useTranslation();
	const { id } = useParams<MapViewViewParams>();

	const classes = getClasses();
	const [graveyardId] = id ? id.split('-') : ['', '', '', '', ''];
	const { originalgraveid } = grave;

	const graveList = useRecoilValue(graveListAtom);

	const congregations: Congregation[] = useMemo(
		() => t('graveyards', { returnObjects: true }),
		[t]
	);
	const congregation = useMemo(
		() =>
			congregations.find((congregation: Congregation) => {
				return congregation.graveyards.some((graveyard) => graveyard.id === graveyardId);
			}),
		[congregations, graveyardId]
	);

	const graveMapUrl = useMemo(
		() =>
			encodeURI(
				congregation?.mapUrl
					?.replace(CITINOMADI_GRAVE_ID_URL_PARAM, id)
					?.replace(HAUTAKARTTA_GRAVE_ID_URL_PARAM, originalgraveid) || ''
			),
		[congregation, id, originalgraveid]
	);

	const toggleDrawer = (open: boolean) => (event: any) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setExpandDrawer(open);
	};

	/**
	 * If there is no search list, return to front page. This is the case if we come to the map page with direct url.
	 */
	function goBack() {
		if (graveList.graves.length === 0) {
			history.push('/');
		} else {
			history.goBack();
		}
	}

	return (
		<>
			<Grid container className={classes.topContainer}>
				<Grid item xs={12}>
					<Container display="flex" className={classes.buttonTopBox}>
						<Button
							id="map-back-button"
							className={classes.headerButtons}
							startIcon={<ArrowBack />}
							onClick={goBack}
						>
							{graveList.graves.length === 0
								? t('back-frontpage')
								: t('back-search-results')}
						</Button>

						{desktopSize && (
							<Button
								id="full-screen-button"
								className={`${classes.headerButtons} ${classes.widthButton}`}
								endIcon={fullWidth ? <FullscreenExit /> : <Fullscreen />}
								onClick={() => setFullWidth(!fullWidth)}
							>
								{fullWidth ? t('show-grave-info') : t('full-screen-map')}
							</Button>
						)}
					</Container>
				</Grid>
			</Grid>

			<Grid container className={classes.mainContainer}>
				{desktopSize && !fullWidth && (
					<Grid item md={4}>
						<InfoSection />
					</Grid>
				)}
				<Grid item xs={12} md={fullWidth ? 12 : 8} className={classes.iframeArea}>
					<iframe
						title="kartta"
						src={graveMapUrl}
						width="100%"
						height="100%"
						allow="geolocation"
					/>
				</Grid>

				<Hidden mdUp>
					<Grid item xs={12}>
						<Container display="flex" className={classes.buttonBottomBox}>
							<Button
								id="map-back-button"
								className={classes.headerButtons}
								startIcon={<Add />}
								onClick={toggleDrawer(true)}
								aria-expanded={expandDrawer}
							>
								{t('show-grave-info')}
							</Button>
						</Container>

						<SwipeableDrawer
							anchor="bottom"
							open={expandDrawer}
							onClose={toggleDrawer(false)}
							onOpen={toggleDrawer(true)}
						>
							<Button
								id="map-back-button"
								variant="contained"
								aria-label={t('hide-grave-info')}
								aria-expanded={expandDrawer}
								color="primary"
								className={classes.buttonCloseBox}
								startIcon={<Remove />}
								onClick={toggleDrawer(false)}
							>
								{t('hide-grave-info')}
							</Button>

							<InfoSection />
						</SwipeableDrawer>
					</Grid>
				</Hidden>
			</Grid>
		</>
	);
}
