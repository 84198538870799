import { makeStyles } from '@material-ui/core/styles';

const getClasses = makeStyles((theme) => ({
	topContainer: {
		[theme.breakpoints.up('md')]: {
			backgroundColor: theme.palette.primary.main,
			flex: '0',
			alignSelf: 'flex-start',
		},
	},
	mainContainer: {
		[theme.breakpoints.up('md')]: {
			flex: '12',
		},
	},
	infoSection: {
		backgroundColor: '#FFFFFF',
		marginTop: '0px',
		paddingBottom: '40px',
		paddingLeft: '0px',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: '20px',
		},
		[theme.breakpoints.up('md')]: {
			minHeight: '500px',
			paddingLeft: '0px',
		},
	},
	infoSectionHeader: {
		color: '#000000',
		textAlign: 'left',
		padding: '20px 20px 0px 20px',
		marginBlockStart: '0px',
	},
	headerButtons: {
		color: '#FFFFFF',
		margin: '10px',
	},
	widthButton: {
		marginLeft: 'auto',
	},
	buttonTopBox: {
		border: 'none',
		[theme.breakpoints.down('sm')]: {
			position: 'fixed',
			top: 'calc(64px + 70px)',
			width: '94%',
			left: '3%',
			backgroundColor: theme.palette.primary.main,
			zIndex: 1,
		},
		[theme.breakpoints.down('xs')]: {
			position: 'fixed',
			backgroundColor: theme.palette.primary.main,
			width: '100%',
			height: '54px',
			top: '0px',
			zIndex: 1,
		},
	},
	buttonBottomBox: {
		border: 'none',
		[theme.breakpoints.down('sm')]: {
			position: 'fixed',
			backgroundColor: theme.palette.primary.main,
			width: '100%',
			height: '54px',
			bottom: '0px',
			zIndex: 1,
		},
	},
	iframeArea: {
		paddingBottom: '0px',
		backgroundColor: '#FFFFFF',
		[theme.breakpoints.down('sm')]: {
			position: 'fixed',
			top: 'calc(64px + 70px + 54px)',
			height: 'calc(100vh - 108px - 64px - 70px)',
			width: '94%',
			left: '3%',
			zIndex: 1,
		},
		[theme.breakpoints.down('xs')]: {
			position: 'fixed',
			top: '54px',
			height: 'calc(100vh - 108px)',
			left: '0px',
			width: '100%',
			zIndex: 1,
		},
		[theme.breakpoints.up('sm')]: {
			minHeight: '500px',
		},
	},
	buttonCloseBox: {
		border: 'none',
		borderRadius: '0px',
		justifyContent: 'left',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			height: '54px',
			bottom: '0px',
		},
	},
	containerExternalButton: {
		marginTop: '1rem',
		display: 'flex',
		justifyContent: 'center',
	},
}));

export default getClasses;
