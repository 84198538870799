import { CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { GraveLocation } from '../../../services/api';
import { trackEvent } from '../../../services/applicationInsight';
import { useStateGrave } from '../../common/useStateGrave';
import { GraveDataTable } from '../../graveListView/GraveRow';
import getClasses from '../classes';
import ExternalButton from './ExternalButton';

const InfoSection = () => {
	const classes = getClasses();

	// Either use the grave data provided by the state or load it from the cloud by parsing the url
	const grave = useStateGrave();

	useEffect(() => {
		if (grave.fullname) {
			document.title = `Hautahaku: ${grave.fullname}`;
		} else {
			document.title = 'Hautahaku';
		}

		return () => {
			document.title = 'Hautahaku';
		};
	}, [grave]);

	const [graveyard, area, block, row, graveId] =
		grave && grave.graveid ? grave.graveid.split('-') : ['', '', '', '', ''];
	const graveLocation: GraveLocation = { graveyard, area, block, row, graveId };

	if (grave.graveid === '') {
		return (
			<div className={classes.infoSection}>
				<CircularProgress />
			</div>
		);
	}

	trackEvent('GraveMap');

	return (
		<div className={classes.infoSection}>
			<h2 className={classes.infoSectionHeader}>{grave.fullname}</h2>
			<GraveDataTable grave={grave} graveLocation={graveLocation} showMore={true} noLinks />

			<ExternalButton grave={grave} />
		</div>
	);
};

export default InfoSection;
